

/*html,body {
    border-image-width: 0;
}*/

#main {
    height: 100%;
    /*height: stretch;*/
}
#wrapper {
    -webkit-overflow-scrolling: touch;
    display: -ms-flexbox;
    display: block;
    height: 100%;
    /*height: stretch;*/
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -moz-align-items: center;
    -ms-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    z-index: 2;
    /* overflow: hidden;*/
}   
/*
.container {
    /*display: flex;
    width: 100%;
    height: 100%;
    background-size: cover;* /
    min-height: 30rem;
}*/

#GlobalLightingProject > .inner {
    padding: 2rem 2rem;
    width: 80%;
    /*max-width: 65rem;*/
    /*justify-content: center;
    align-content: center;
    align-items: center;*/
    /*display: inline-flex;*/
}

#GlobalLightingProject {
    display: flex;
    flex-direction: column;
    width: 100%;
    /*max-width: 100vw;*/
    /*height: 100%;*/
    height: stretch;
    border-image-width: 0;
    border-color: #006dd3;
    border-top: 10px #006dd3;
    border-top-width: 10px;
    border-style: solid;
    -moz-align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    background-attachment: fixed;
    /*background-image: linear-gradient(to top, rgba(0,0,0,0.553), rgba(0,0,0,0.553)), url('../images/album-cover-bg.jpg');*/
    background-position: center;
    background-position-x: 50% 50% 50%;
    background-position-y: 50% 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%220%200%20512%20512%22%20width%3D%22512%22%20height%3D%22512%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cfilter%20id%3D%22noise%22%3E%3CfeTurbulence%20type%3D%22fractalNoise%22%20baseFrequency%3D%220.875%22%20result%3D%22noise%22%20%2F%3E%3CfeColorMatrix%20type%3D%22matrix%22%20values%3D%220%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200.46875%200%22%20%2F%3E%3C%2Ffilter%3E%3Crect%20filter%3D%22url%28%23noise%29%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22512%22%20height%3D%22512%22%20fill%3D%22transparent%22%20opacity%3D%221%22%20%2F%3E%3C%2Fsvg%3E'), /*linear-gradient(to top, rgba(43,50,94,0.412), rgba(43,50,94,0.412)),*/ url('../../images/cdcover-glp.jpg');
    
}
#GlobalLightingProject {
    min-height: 40rem;
}

.SpotifyPlayer {
    width: 600px;
    height: 420px;
}

.wmp-container {
    width: 600px;
    height: auto;
}
/*
div.backrow {
    position: relative;
    justify-content: left;
    display: flex;
    margin: 1rem;
    text-align: left;
    top: 0;
}

div.backcol {
    text-align: left;
}
.back {
    color: #292525;
}

svg.back {
    fill: #eaeaea;
    stroke-width: 1.3rem;
    stroke: #ffb917; 
}

.back02 {
    color: #292525;
}

svg.back02 {
    position: relative;
    left: -1.7rem;
    fill: #eaeaea;
    stroke-width: 1.3rem;
    stroke: #ffb917;   
}
*/
div.glplogo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 2rem 1rem;
    position: relative;
}
img.glplogo {
    width: 1000px;
    margin: auto;
    
}
img.glpalbum {
    width: 500px;
    height: 500px;
    opacity: 0.85;
    box-shadow: 3px 3px 7px black;
    margin: 3rem 0;
    padding: 0;
}

div.glpsongs {
    margin: 1rem 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /*padding: 50px;
    margin: 50px;
    align-items: center;
    justify-content: center;*/

}

div.nomobile2 {
    display: block;
}


div.mobile-slider2 {
    display: none;
}



@media (min-width: 2400px) {
    #GlobalLightingProject {
        /*max-height: 140vh;*/
        /*height: 100%;*/
        height: stretch;
    }
    #GlobalLightingProject > .inner {
        width: 90%;
        padding: 2rem 1.5rem;
    }

    /*
    div.backrow {
        position: relative;
        justify-content: left;
        display: flex;
        margin: 1rem;
        text-align: left; 
        
    }
*/
    div.nomobile2 {
        display: block;
    }

    div.mobile-slider2 {
        display: none;
    }
}
@media (max-width: 1680px) {
    html {
        font-size: 13pt;
    }
    /*#GlobalLightingProject {
        /*display: flex;
        width: 100%;
        width: 100vw;
        height: 100vh;
        height: 100%;
        min-height: 100%;*/
        /*min-height: 50rem;*/
       /* height: 100%;
        height: 100vh;
        -moz-align-items: center;
        -webkit-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;* /
        background-attachment: fixed;
        border-style: solid;
        /*border-color: #00FFD1;* /
        /*border-color: #FFB917;
        border-top-width: 10px;* /
        background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%220%200%20512%20512%22%20width%3D%22512%22%20height%3D%22512%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cfilter%20id%3D%22noise%22%3E%3CfeTurbulence%20type%3D%22fractalNoise%22%20baseFrequency%3D%220.875%22%20result%3D%22noise%22%20%2F%3E%3CfeColorMatrix%20type%3D%22matrix%22%20values%3D%220%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200.46875%200%22%20%2F%3E%3C%2Ffilter%3E%3Crect%20filter%3D%22url%28%23noise%29%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22512%22%20height%3D%22512%22%20fill%3D%22transparent%22%20opacity%3D%221%22%20%2F%3E%3C%2Fsvg%3E'), /*linear-gradient(to top, rgba(43,50,94,0.412), rgba(43,50,94,0.412)),* / url('../images/song-art/global-lighting-album/album-cover-glp-full.jpg');
        background-size: cover; 
        background-position: center;
        background-repeat: no-repeat 
    }*/
    #GlobalLightingProject > .inner {
        /*width: 100%;*/
        padding: 2rem 1.5rem;
    }
}

@media (max-width: 1280px) {
    html {
        font-size: 13pt;
    }
    /*##GlobalLightingProject {
        /*background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;* /
       /* margin: 0;
        max-height: 100vh;* /
    }*/
    
    #GlobalLightingProject > .inner {
        width: 90%;
        padding: 2rem 1.5rem;
        display: block;
    }
}
@media (max-width: 1150px) {
    /*#FeedThePeople {
        padding: 0 0 8rem;
    }*/
    div.nomobile2 {
        display: none!important;
    }
    div.mobile-slider2 {
        display: flex;
        flex-direction: column;
        max-width: 100%;
    }
   /* div.songheaderRow {    
        display: flex;
        justify-content: center;
        text-align: center;
        margin: 2rem 0 5rem;
        padding: 2rem 0 5rem;
        
    }*/
}
@media (max-width: 980px) {
    html {
        font-size: 11pt;
    }
   /* div.glpsongs {
        /*margin: 1rem 2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;* /
    }*/
    #GlobalLightingProject {
        height: 50em;
        padding: 0 0 6em;
    }
    /*div.nomobile2 {
        display: none !important;
    }
    div.mobile-slider2{
        display: flex !important;
    }*/

    div.glplogo {
        width: 100% ;
    }
    img.glplogo {
        max-width: 100%;
        margin: auto;
        width: auto;
    }
        div.nomobile2 {
            display: none!important;
        }
        div.mobile-slider2 {
            display: flex!important;
        }
        #GlobalLightingProject > .inner {
            width: 90%;
            padding: 6rem 1.5rem;
            display: block;
        }
    }


@media (max-width: 736px) {
    html {
        font-size: 11pt;
    }
    
    .wmp-container {
        width: 350px;
        height: auto;
    }

  /*  div.glpsongs {
        /*margin: 1rem 2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;* /
    }
        */

    div.nomobile2 {
        display: none;
    }
    div.mobile-slider2 {
        display: flex !important;
    }
    
    .arrowleft2 {
        display: flex!important;
        font-size: 1.5em;
        color: white;
        top: 15em;
        left: 10%;
        position: absolute;
        z-index: 100;                                                                          
    }
    .arrowright2 {
        display: flex!important;
        font-size: 1.5em;
        color: white;
        top: 15em;
        left: 80%;
        position: absolute;
        z-index: 100;                                                                          
    }*/
    div.glplogo {
        width: 100% ;
    }
    img.glplogo {
        max-width: 100%;
        margin: auto;
        width: auto;
        
    }
    
}    

    @media (max-width: 480px) {
        #GlobalLightingProject {
            height: 40em;
            padding: 0 0 6em;
            background-attachment: scroll;
        }
      
        .wmp-container {
            width: 300px;
            height: auto;
        }
        
        #GlobalLightingProject > .inner {
            padding: 0 1.5rem;
        }
        
        div.backrow {
            top: -4rem;
        }
       
        /*div.back {
            position: relative;
            top: -300px;
            left: -150px;
            
        }*/

        div.glpsongs {
        margin: 1rem 0;
        
         }

      
        /*.arrowleft2 {
            display: flex!important;
            font-size: 1.5em;
            color: white;
            top: 15em;
            left: 10%;
            position: absolute;
            z-index: 100;                                                                          
        }
        .arrowright2 {
            display: flex!important;
            font-size: 1.5em;
            color: white;
            top: 15em;
            left: 80%;
            position: absolute;
            z-index: 100;                                                                          
        }*/
        div.glplogo {
            width: 100%;
            margin: 0;
        }
        img.glplogo {
            max-width: 100%;
            width: auto;
            margin: auto;
            
        }
        div.nomobile2 {
            display: none;
        }
        div.mobile-slider2 {
            display: flex!important;
        }
        
    }
    @media (max-width: 360px) {
     
    /*    div.glpsongs {
            /*margin: 1rem 2rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        } */
        div.glplogo {
            width: 100% ;
        }
        img.glplogo {
            max-width: 100%;
            margin: auto;
            width: auto;
            
        }
        div.nomobile2 {
            display: none;
        }
        div.mobile-slider2 {
            display: flex!important;
        }
}
