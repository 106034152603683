/*.container {
    display: flex;
    width: 100%;
    background-size: cover, auto; 
}*/
#FeedThePeople > .inner {
    padding: 2rem 2rem;
    width: 80%;
    /*max-width: 65rem;*/
}

#FeedThePeople {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100vw;
    /*min-height: 40rem;*/
    -moz-align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    background-attachment: fixed;
    border-style: solid;
    /*border-color: #00FFD1;*/
    border-color: #FFB917;
    border-top-width: 10px;
    /*background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%220%200%20512%20512%22%20width%3D%22512%22%20height%3D%22512%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cfilter%20id%3D%22noise%22%3E%3CfeTurbulence%20type%3D%22fractalNoise%22%20baseFrequency%3D%220.875%22%20result%3D%22noise%22%20%2F%3E%3CfeColorMatrix%20type%3D%22matrix%22%20values%3D%220%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200.46875%200%22%20%2F%3E%3C%2Ffilter%3E%3Crect%20filter%3D%22url%28%23noise%29%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22512%22%20height%3D%22512%22%20fill%3D%22transparent%22%20opacity%3D%221%22%20%2F%3E%3C%2Fsvg%3E'), linear-gradient(to top, rgba(43,50,94,0.412), rgba(43,50,94,0.412)), url('../../images/container02.jpg');*/
    background-image: linear-gradient(to top, rgba(0,0,0,0.553), rgba(0,0,0,0.553)), url('../../images/album-cover-bg.jpg');
    background-size:  cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 2;
}
/*

*/
/*
*/
/*
#FeedThePeople > .inner {
    padding: 1.5rem 0.5rem;
    max-width: 100%;
}
*/
#FeedThePeople {
    min-height: 40rem;
}
/*
*/

.SpotifyPlayer {
    width: 600px;
    height: 420px;
}


div.songheaderRow {
    display: flex;
    justify-content: center;
    text-align: center;
    position: relative;
    margin: 3rem;
}


h2.songheader {
        font-family: 'Source Sans Pro', sans-serif;
        color: #FCAC02 !important;
        font-weight: 500;
        font-feature-settings: "c2sc";
        font-variant: small-caps;
        text-align: center;
        font-size: 4rem;
        text-shadow: 3px 3px 6px black;
        letter-spacing: 2px;
        margin: 2rem auto;
}

p.songheader {
    font-weight: 500;
    font-variant: small-caps;
    text-align: center;
    margin: 0px;
    font-size: 1.5rem;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: 2px;
    line-height: normal;
    text-shadow: 3px 3px 6px black;
    color: #FFB917;
}

div.ftpsongs {
    margin: 1rem auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

div.nomobile{
    display: block;
}
div.mobile-slider {
    display: none;
}

.plzwork {
    cursor: pointer;
    min-width: 175px;
    max-width: 175px;
}


@media (min-width: 2400px) {
    #FeedThePeople > .inner {
        padding: 2rem 1.5rem;
        width: 90%;
        /*max-width: 65rem;*/
    }
    #FeedThePeople {
        display: flex;
        flex-direction: column;
        width: 100%;
        /*width: 100vw;*/
        min-height: 40rem;
        -moz-align-items: center;
        -webkit-align-items: center;
        -ms-align-items: center;
        align-items: center;
        -moz-justify-content: center;
        -webkit-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        background-attachment: fixed;
        border-style: solid;
        /*border-color: #00FFD1;*/
        border-color: #FFB917;
        border-top-width: 10px;
        /*background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%220%200%20512%20512%22%20width%3D%22512%22%20height%3D%22512%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cfilter%20id%3D%22noise%22%3E%3CfeTurbulence%20type%3D%22fractalNoise%22%20baseFrequency%3D%220.875%22%20result%3D%22noise%22%20%2F%3E%3CfeColorMatrix%20type%3D%22matrix%22%20values%3D%220%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200%200.46875%200%22%20%2F%3E%3C%2Ffilter%3E%3Crect%20filter%3D%22url%28%23noise%29%22%20x%3D%220%22%20y%3D%220%22%20width%3D%22512%22%20height%3D%22512%22%20fill%3D%22transparent%22%20opacity%3D%221%22%20%2F%3E%3C%2Fsvg%3E'), linear-gradient(to top, rgba(43,50,94,0.412), rgba(43,50,94,0.412)), url('../../images/container02.jpg');*/
        background-image: linear-gradient(to top, rgba(0,0,0,0.553), rgba(0,0,0,0.553)), url('../../images/album-cover-bg.jpg');
        background-size:  cover;
        background-position: center;
        background-repeat: no-repeat;
        /*z-index: 2;*/
    }
    /*#FeedThePeople {
        min-height: 35rem;
    }*/
    div.songheaderRow {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-bottom: 1em;
        margin-top: 2em;
    }
    img.songheader {
        width: 1000px;
    }

    div.nomobile {
        display: block;
    }
    div.mobile-slider {
        display: none;
    }
}

/*
@media (max-width: 1680px) {
    html {
        font-size: 13pt;
    }
    #FeedThePeople > .inner {
        /*width: 100%;* /
        padding: 2rem 1.5rem;
    }

    div.nomobile {
        display: block;
    }
    div.mobile-slider {
        display: none;
    }
}
*/
/*
@media (max-width: 1280px) {
    html {
        font-size: 13pt;
    }
    #FeedThePeople {
        background-attachment: fixed;
    }
    .SpotifyPlayer {
        width: 600px;
        height: 420px;
    }
    div.nomobile {
        display: block;
    }
    div.mobile-slider {
        display: none;
    }
    #FeedThePeople > .inner {
        width: 90%;
        padding: 2rem 1.5rem;
        display: block;
    }
}
*/

@media (min-width: 1279px) {
    html {
        font-size: 13pt;
    }
    #FeedThePeople {
        background-attachment: fixed;
    }
    .SpotifyPlayer {
        width: 600px;
        height: 420px;
    }
    #FeedThePeople > .inner {
        width: 90%;
        padding: 2rem 1.5rem;
        display: block;
    }
}

@media (max-width: 1278px) {
    /*#FeedThePeople {
        padding: 0 0 8rem;
    }*/
    
    div.nomobile {
        display: none;
    }
    div.mobile-slider{
        display: flex ;
        /*z-index: 10;*/
        flex-direction: column;
        max-width: 100%;
    }
    
    div.songheaderRow {    
        display: flex;
        justify-content: center;
        text-align: center;
        margin: 2rem 0 5rem;
        padding: 2rem 0 5rem;
    }
    #FeedThePeople > .inner {
        padding: 2rem 1rem;
        width: 90%;
    }

}
/*
@media (min-width: 981px){
    div.nomobile {
        display: block;
    }
    div.mobile-slider{
        display: none;
    }
}
*/
@media (max-width: 980px) {
    html {
        font-size: 11pt;
    }
    #FeedThePeople {
        min-height: 50em;
        padding: 0 0 6em;      
    }
    .SpotifyPlayer {
        width: 550px;
        height: 420px;
    }
    div.songheaderRow {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-bottom: 5em;
        padding-bottom: 5em;
    }
    img.songheader {
        width: 700px;
    }
    div.nomobile {
        display: none;
    }
    div.mobile-slider{
        display: flex;
    }

    
}

@media (max-width: 736px) {
    html {
        font-size: 11pt;
    }

    #FeedThePeople {
        min-height: 40rem;
        padding: 0 0 6em;
        background-attachment: fixed;
    }

    div.songheaderRow {
        margin: 0 0 5rem;
        padding: 0 0 5rem; 
    }
    img.songheader {
        width: 450px;
    
    }
    .wmp-container {
        width: 350px;
        height: auto;
    }
    .SpotifyPlayer {
        width: 450px;
        height: 420px;
    }
}    

    @media (max-width: 480px) {
        #FeedThePeople {
            min-height: 40em;
            justify-content: center;
            padding: 0 0 6em;
            background-attachment: scroll;
        }
        #FeedThePeople > .inner {
            padding: 2rem 0.5rem;
            width: 95%;
        }
        
        .wmp-container {
            width: 300px;
            height: auto;
        }
        .SpotifyPlayer {
            width: 350px;
            height: 420px;
        }
        div.mobile-slider {
            display: flex;
            margin: auto;
            padding: 1rem 0;
        }

        div.songheaderRow {
            margin: 2rem auto;
            padding: 0;
        }

        img.songheader {
            width: 350px;
        }

        div.ftpsongs {
            margin: 1rem auto;
        }
        
    }
    @media (max-width: 360px) {
        #FeedThePeople {
            min-height: 20rem;
            background-attachment: scroll;
        }
        .SpotifyPlayer {
            width: 300px;
            height: 420px;
        }
        div.mobile-slider{
            display: flex !important;
        }

        div.songheaderRow {
            margin: 3rem 0 3rem;
        }

        img.songheader {
            width: 300px;
        }
}

