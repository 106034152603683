@font-face {
    font-family: 'DeathFont'; /* Choose a name for your font family */
    src: url('../fonts/DEATH_FONT.woff2') format('woff2'), /* Use WOFF2 if available */
         url('../fonts/DEATH_FONT.woff') format('woff'), /* Use WOFF for fallback */
         url('../fonts/DEATH_FONT.ttf') format('truetype'); /* Use TTF as fallback */
    font-weight: normal; /* Or 'bold', 'italic', etc. */
    font-style: normal; /* Or 'italic' */
  }

html {
    font-size: 18pt;
}
h1 br + br, h2 br + br, h3 br + br, p br + br {
    display: block;
    content: ' ';
}

h1 .li, h2 .li, h3 .li, p .li {
    display: list-item;
    padding-left: 0.5em;
    margin: 0.75em 0 0 1em;
}
/*
h1.style1, h2.style1, h3.style1, p.style1 br + br {
    margin-top: 0.9rem;
}
*/
h1.style1, h2.style1, h3.style1, p.style1 {
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: -0.075rem;
    width: 100%;
    font-size: 2.25em;
    line-height: 1.5;
    font-weight: 900;
}
/*
h1.style2, h2.style2, h3.style2, p.style2 br + br {
    margin-top: 1.2rem;
}
*/
h1.style2, h2.style2, h3.style2, p.style2 {
    color: rgba(255,255,255,0.78);
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: 0.025rem;
    /*width: calc(100% + 0.025rem);*/
    font-size: 1em;
    line-height: 2;
    font-weight: 300;
}
/*
h1.style3, h2.style3, h3.style3, p.style3 br + br {
    margin-top: 0.75rem;
}
*/
h1.style3, h2.style3, h3.style3, p.style3 {
    font-variant: small-caps;
    color: #FFFFFF;
    font-family: 'Big Shoulders Display', cursive;
    /*letter-spacing: -0.075rem;
    width: 100%;*/
    font-size: 5.5em;
    line-height: 1.25;
    font-weight: 900;
}
/*
h1.style4, h2.style4, h3.style4, p.style4 br + br {
    margin-top: 1.2rem;
}
*/
h1.style4, h2.style4, h3.style4, p.style4 {
    color: #FFFFFF;
    font-family: 'Source Sans Pro', sans-serif;
    /*letter-spacing: 0.025rem;
    width: calc(100% + 0.025rem);
    font-size: 1.25em;*/
    line-height: 2;
    font-weight: 300;
}

h1.style5, h2.style5, h3.style5, p.style5 br + br {
    margin-top: 1.2rem;
}

h1.style5, h2.style5, h3.style5, p.style5 {
    color: rgba(255,255,255,0.22);
    font-family: 'Source Sans Pro', sans-serif;
    /*letter-spacing: 0.025rem;
    width: calc(100% + 0.025rem);
    font-size: 1em;*/
    line-height: 2;
    font-weight: 300;
}
.icons {
    cursor: default;
    padding: 0;
    letter-spacing: 0;
}

.icons li {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    /*z-index: 1;*/
}

.icons li a {
    display: flex;
    -moz-align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
}

.icons li a svg {
    display: block;
    position: relative;
    -moz-transition: fill 0.25s ease;
    -webkit-transition: fill 0.25s ease;
    -ms-transition: fill 0.25s ease;
    transition: fill 0.25s ease;
}

.icons li a + svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*z-index: -1;*/
    -moz-pointer-events: none;
    -webkit-pointer-events: none;
    -ms-pointer-events: none;
    pointer-events: none;
    -moz-transition: fill 0.25s ease, stroke 0.25s ease;
    -webkit-transition: fill 0.25s ease, stroke 0.25s ease;
    -ms-transition: fill 0.25s ease, stroke 0.25s ease;
    transition: fill 0.25s ease, stroke 0.25s ease;
}

.icons li a .label {
    display: none;
}

#icons02 {
    font-size: 1em;
    width: calc(100% + 1.25rem);
    margin-left: -0.625rem;
}

#icons02 li {
    margin: 0.625rem;
}

#icons02 li a {
    border-radius: 2rem;
    width: 2em;
    height: 2em;
}

#icons02 li a svg {
    width: 60%;
    height: 60%;
}

#icons02 a svg {
    fill: #FFFFFF;
}

#icons02 a {
    border: solid 1px #FFFFFF;
}

#icons02 a:hover {
    border-color: #00FFD1 !important;
}

#icons02 a:hover svg {
    fill: #00FFD1 !important;
}
/* containerHeader */
p.style4 {
    color: #FCAC02 !important;
    font-weight: 500;
    font-variant: small-caps;
    text-align: center;
    margin: 1em 0 6em 0;
}


@media (min-width: 2400px) {
    /*containerGLP */
    h1.style2, h2.style2, h3.style2, p.style2 {
        color: rgba(255,255,255,0.78);
        font-family: 'Source Sans Pro', sans-serif;
        /*letter-spacing: 0.025rem;
        width: calc(100% + 0.025rem);*/
        font-size: 1.3em;
}
    /* containerHeader */
    p.style4 {
        color: #FCAC02 !important;
        font-weight: 500;
        font-size: 2rem;
        font-variant: small-caps;
        text-align: center;
        margin: 1em 3em 1em 1em;
    }
}
@media (max-width: 1680px) {
    html {
        font-size: 13pt;
    }
}

@media (max-width: 1280px) {
    html {
        font-size: 13pt;
    }

    /* containerHeader */
    p.style4 {
        color: #FCAC02 !important;
        font-weight: 500;
        font-variant: small-caps;
        text-align: center;
        margin: 1em 5em 1em 1em;
}
}

@media (max-width: 980px) {
    html {
        font-size: 11pt;
    }
    /*containerHeader */
    p.style4 {
        color: #FCAC02 !important;
        font-weight: 500;
        font-variant: small-caps;
        text-align: center;
        margin: 1em 5em 1em 1em;
        margin: 1em 0 6em;
    }
}

@media (max-width: 736px) {
    html {
        font-size: 11pt;
    }
    h1.style1, h2.style1, h3.style1, p.style1 {
       /* letter-spacing: -0.065625rem;
        width: 100%;*/
        font-size: 2em;
        line-height: 1.5;
    }

    h1.style2, h2.style2, h3.style2, p.style2 {
      /*  letter-spacing: 0.021875rem;
        width: calc(100% + 0.021875rem);*/
        font-size: 1em;
        line-height: 2;
    }

    h1.style3, h2.style3, h3.style3, p.style3 {
      /*  letter-spacing: -0.065625rem;
        width: 100%;*/
        font-size: 3.5em;
        line-height: 1.25;
    }

    h1.style4, h2.style4, h3.style4 /*p.style4*/ {
       /* letter-spacing: 0.021875rem;
        width: calc(100% + 0.021875rem);*/
        font-size: 1.25em;
        line-height: 2;
    }

    p.style4 {
       /* letter-spacing: 0.021875rem;
        width: calc(100% + 0.021875rem);*/
        font-size: 2.25em;
        line-height: 2;
    }
    h1.style5, h2.style5, h3.style5, p.style5 {
       /* letter-spacing: 0.021875rem;
        width: calc(100% + 0.021875rem);*/
        font-size: 1em;
        line-height: 2;
    }
    /* containerHeader */
    p.style4 {
        color: #FCAC02 !important;
        font-weight: 700;
        font-variant: small-caps;
        text-align: center;
        margin: 1em 1em 1em;
}
}

@media (max-width: 480px) {
    /*container bio */
    h1.style1, h2.style1, h3.style1, p.style1 {
       /* letter-spacing: -0.065625rem;
        width: 100%;*/
        font-size: 1.5em;
        line-height: 1.5;
        
    }

    p.style1 {
        font-size: 1.5em;
        text-align: center!important;
    }
}
@media (max-width: 360px) {
    /* next 2, container bio */
    h1.style1, /*h2.style1,*/ h3.style1, p.style1 {
        font-size: 2em;
    }
    h2.style1 {
        margin-bottom: 1rem;
        color: #7c795d;
        font-size: 45px;
        font-weight: normal;
        line-height: 48px;
        margin: 0;
    }
    /* next 2, container bio */
    h1.style2, h2.style2, h3.style2 /*p.style2*/ {
        font-size: 1em;
    }

    p.style2 {
        color: white;
        font-size: 18px;
        line-height: 32px;
        margin: 0 0 24px;
    }
    
    h1.style5, h2.style5, h3.style5, p.style5 {
        /*letter-spacing: 0.021875rem;
        width: calc(100% + 0.021875rem);*/
        font-size: 1em;
        line-height: 2;
    }
    h1.style1, h2.style1, h3.style1, p.style1 {
        font-size: 2em;
    }

    h1.style2, h2.style2, h3.style2, p.style2 {
        font-size: 1em;
    }

    h1.style3, h2.style3, h3.style3, p.style3 {
        font-size: 3.5em;
    }

    h1.style4, h2.style4, h3.style4, p.style4 {
        font-size: 1.25em;
    }

    h1.style5, h2.style5, h3.style5, p.style5 {
        font-size: 1em;
    }

    

}

